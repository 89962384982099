import React from "react";

const Footer = () => {
  return (
    <div className="shadow bg-white">
      <div className="py-3" style={{ paddingLeft: "4%" }}>
        <div className="title">2024 © Zithas Technologies.</div>
      </div>
    </div>
  );
};

export default Footer;
