import React from "react";
import "../../Assets/Css/Main.css";
import logo from "../../Assets/Images/logo192.avif";
import { Link } from "react-router-dom";
import { ConfigUrl } from "../ConfigUrl";

const Nav = () => {
  return (
    <div className="">
      <nav
        className="navbar navbar-expand-lg position-sticky shadow-sm"
        style={{ paddingRight: "4%", paddingLeft: "4%" }}
      >
        <div className="container-fluid">
          <Link className="navbar-brand" to={`${ConfigUrl.appUrl}`}>
            <img src={logo} alt="logo" className="" width={160} height={50} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Link
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Dropdown
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" aria-disabled="true">
                  Disabled
                </a>
              </li>
            </ul> */}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
