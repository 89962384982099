import React, { useEffect, useState } from "react";
import "../Assets/Css/Home.css";
import ReactSelect from "react-select";
import Nav from "../Components/Common/Nav";
import { Link, useNavigate } from "react-router-dom";
import { ConfigUrl } from "../Components/ConfigUrl";
import Footer from "../Components/Common/Footer";
import swal from "sweetalert";
import axios from "axios";
import Loader from "../Components/Common/Loader";

const Home = () => {
  const [loading, seLoading] = useState(false);
  const [services, setServices] = useState({
    label: null,
    value: null,
  });
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [bookId, setBookId] = useState("");

  const navigate = useNavigate();
  const handleNextButtonClick = () => {
    navigate(`${ConfigUrl.appUrl}/booking`);
  };
  const location = [
    {
      label: "BUSINESS SOFTWARE AUDIT",
      value: "5",
    },
    {
      label: "BUSINESS AUTOMATION",
      value: "6",
    },
    {
      label: "CUSTOM SOFTWARE DEVELOPMENT",
      value: "7",
    },
    {
      label: "WEBSITE DEVELOPMENT",
      value: "8",
    },
    {
      label: "MOBILE APPS DEVELOPMENT",
      value: "9",
    },
    {
      label: "HIRE A RESOURCE",
      value: "10",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: "0",
      behavior: "instant",
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      swal("", "Invalid Email Address!", "warning");
      return;
    }
    if (phone.length < 10) {
      swal("", "Invalid phone number!", "warning");
      return;
    }
    seLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("services", services.value);
    formData.append("type", 2);
    try {
      const response = await axios.post(
        `${ConfigUrl.apiUrl}addBookings`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success) {
        // console.log(response);
        seLoading(false);
        setBookId(response.data.data);
        navigate(`${ConfigUrl.appUrl}bookslot`, {
          state: { bookId: response?.data.data },
        });
      }
    } catch (error) {
      seLoading(false);
      console.log(error);
    }
  };
  return (
    <div>
      {loading && <Loader />}
      <Nav />
      <div className="bg-main">
        <section className="">
          <div className="container-main">
            <div className="row">
              <div className="col-md-7">
                <div>
                  <h1 className="scheduling mb-1">Get Expert Guidance</h1>
                  <h1 className="scheduling">Book Free 30-Min Session</h1>
                </div>
                <div>
                  <p className="pb-3 fs-5">
                    Quickly book meetings: add details, choose time, and reserve
                    your slot without hassle!
                  </p>
                </div>
                <div>
                  <ul className="ps-0">
                    <div className="d-flex align-items-center fs-5 mb-3">
                      <i className="fa-solid fa-check main-color"></i>
                      <li className=" ps-3">
                        <span className="">
                          Enter your details and Choose the service you want and
                          proceed
                        </span>
                      </li>
                    </div>
                    <div className="d-flex align-items-center fs-5 mb-3">
                      <i className="fa-solid fa-check main-color"></i>
                      <li className=" ps-3">
                        <span className="">
                          Select your preferred date and time to book your
                          appointment.
                        </span>
                      </li>
                    </div>
                    <div className="d-flex align-items-center fs-5 mb-3">
                      <i className="fa-solid fa-check main-color"></i>
                      <li className=" ps-3">
                        <span className="">
                          You will get an Email from our Side with the link to
                          join the meeting.
                        </span>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
              <div className="col-md-5 ">
                <div className="shadow-lg bg-white px-2">
                  <div>
                    <div className="row d-flex py-xxl-5 align-items-center">
                      <div className="col-md-12 px-mbd-0">
                        <div className="py-3">
                          <div
                            className="p-4 wow bounceIn bounceIn-mbd"
                            data-wow-delay="0.5s"
                            style={{
                              visibility: "visible",
                              animationDelay: "0.5s",
                              animationName: "bounceIn",
                            }}
                          >
                            <h3 className="text-mbd-center">
                              Make An Appointment
                            </h3>

                            <div>
                              <p
                                className="register_success_box"
                                style={{ display: "none" }}
                              >
                                We received your message and you'll hear from us
                                soon. Thank You!
                              </p>

                              <form
                                onSubmit={handleSubmit}
                                className="register-form register"
                              >
                                <div className="input-icon">
                                  <i className="fa-solid fa-user-tie"></i>
                                  <input
                                    className="register-input name-input white-input"
                                    required
                                    name="register_names"
                                    placeholder="Name*"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>

                                <div className="input-icon">
                                  <i className="fas fa-envelope"></i>
                                  <input
                                    className="register-input name-email white-input"
                                    required
                                    name="register_email"
                                    placeholder="Email Adress*"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                                <div className="input-icon">
                                  <i className="fas fa-phone"></i>
                                  <input
                                    className="register-input name-phone white-input"
                                    required
                                    name="register_email"
                                    placeholder="Phone Number*"
                                    type="number"
                                    value={phone}
                                    onChange={(e) => {
                                      setPhone(e.target.value.slice(0, 12));
                                    }}
                                  />
                                </div>
                                <div className="mb-3">
                                  <ReactSelect
                                    placeholder="Services"
                                    className="basic-multi-select"
                                    classNamePrefix="border-radius select"
                                    // styles={colourStyles}
                                    options={location}
                                    required
                                    // value={{
                                    //   label: services.label,
                                    //   value: services.value,
                                    // }}
                                    onChange={(selectedOption) => {
                                      setServices({
                                        value: selectedOption.value,
                                        label: selectedOption.label,
                                      });
                                    }}
                                  />
                                </div>
                                {/* <Link to={`${ConfigUrl.appUrl}bookslot`}> */}
                                <button
                                  id="modalSubmit"
                                  className="register-submit btn w-100"
                                  type="submit"
                                  // onClick={() => handleNextButtonClick()}
                                >
                                  Next
                                </button>
                                {/* </Link> */}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="d-flex justify-content-center text-muted px-4 pb-3">
                        Rest assured, your data is safe with us. We strictly
                        adhere to GDPR compliance standards.
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
