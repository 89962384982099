import logo from "./logo.svg";
import "./App.css";
import Nav from "./Components/Common/Nav";
import Home from "./Pages/Home";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "@fortawesome/fontawesome-free/css/all.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import CalenderIndex from "./Pages/CalenderIndex.jsx";
import { ConfigUrl } from "./Components/ConfigUrl.js";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path={`${ConfigUrl.appUrl}`} element={<Home />} />
          <Route
            path={`${ConfigUrl.appUrl}bookslot`}
            element={<CalenderIndex />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
