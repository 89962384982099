import React, { useEffect, useState } from "react";
import DatePicker from "./DatePicker";
import moment from "moment";
import axios from "axios";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { ConfigUrl } from "../Components/ConfigUrl";
import Nav from "../Components/Common/Nav";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../Components/Common/Footer";
import { Modal, ModalBody } from "reactstrap";

const CalenderIndex = () => {
  const [prevDate, setPrevDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [isWeekendDate, setIsWeekendDate] = useState("");
  const [timeIds, setTimeIds] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [modalCreateMeeting, setModalCreateMeeting] = useState(false);
  const [email, setEmail] = useState("");
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "Creating your meeting...",
    text: "Please wait a moment.",
    icon: "fa-hourglass spin-hourglass",
  });
  const [bookingIdMain, setBookingIdMain] = useState("");

  const [time, setTime] = useState("");
  const bookId = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const bookingId = bookId?.state?.bookId;
    localStorage.setItem("bookId", bookingId);
    setBookingIdMain(localStorage.getItem("bookId"));
    return () => {
      localStorage.removeItem("bookId");
    };
  }, []);
  const toggleModalCreateMeeting = () => {
    setModalCreateMeeting(!modalCreateMeeting);
  };
  const handleSlotClick = (slot) => {
    setSelectedSlot(slot.id);
    setTime(`${slot.from}-${slot.to}`);
  };
  const [selectedTimeZone, setSelectedTimeZone] = useState({
    label: "Europe/London",
    value: "Europe/London",
  });
  const isWeekend = (date) => {
    const dayOfWeek = moment(date).day(); // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)

    return dayOfWeek === 6 || dayOfWeek === 0; // Return true if it's Saturday (6) or Sunday (0), false otherwise
  };
  const timeSlots = [
    { id: 1, from: "9:00 AM", to: "9:30 AM" },
    { id: 2, from: "9:30 AM", to: "10:00 AM" },
    { id: 3, from: "10:00 AM", to: "10:30 AM" },
    { id: 4, from: "10:30 AM", to: "11:00 AM" },
    { id: 5, from: "11:00 AM", to: "11:30 AM" },
    { id: 6, from: "11:30 AM", to: "12:00 PM" },
    { id: 7, from: "12:00 PM", to: "12:30 PM" },
    { id: 8, from: "12:30 PM", to: "01:00 PM" },
    { id: 9, from: "01:00 PM", to: "01:30 PM" },
    { id: 10, from: "01:30 PM", to: "02:00 PM" },
    { id: 11, from: "02:00 PM", to: "02:30 PM" },
    { id: 12, from: "03:00 PM", to: "03:30 PM" },
    { id: 13, from: "03:30 PM", to: "04:00 PM" },
    { id: 14, from: "04:00 PM", to: "04:30 PM" },
    { id: 15, from: "04:30 PM", to: "05:00 PM" },
    { id: 16, from: "05:00 PM", to: "05:30 PM" },
    { id: 17, from: "05:30 PM", to: "06:00 PM" },
    { id: 18, from: "06:30 PM", to: "07:00 PM" },
    { id: 19, from: "07:00 PM", to: "07:30 PM" },
  ];
  const [filterTimeSlots, setFilterTimeSlots] = useState([]);
  const aryIanaTimeZones = [
    "Europe/Andorra",
    "Asia/Dubai",
    "Asia/Kabul",
    "Europe/Tirane",
    "Asia/Yerevan",
    "Antarctica/Casey",
    "Antarctica/Davis",
    "Antarctica/DumontDUrville",
    "Antarctica/Mawson",
    "Antarctica/Palmer",
    "Antarctica/Rothera",
    "Antarctica/Syowa",
    "Antarctica/Troll",
    "Antarctica/Vostok",
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Cordoba",
    "America/Argentina/Salta",
    "America/Argentina/Jujuy",
    "America/Argentina/Tucuman",
    "America/Argentina/Catamarca",
    "America/Argentina/La_Rioja",
    "America/Argentina/San_Juan",
    "America/Argentina/Mendoza",
    "America/Argentina/San_Luis",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Ushuaia",
    "Pacific/Pago_Pago",
    "Europe/Vienna",
    "Australia/Lord_Howe",
    "Antarctica/Macquarie",
    "Australia/Hobart",
    "Australia/Currie",
    "Australia/Melbourne",
    "Australia/Sydney",
    "Australia/Broken_Hill",
    "Australia/Brisbane",
    "Australia/Lindeman",
    "Australia/Adelaide",
    "Australia/Darwin",
    "Australia/Perth",
    "Australia/Eucla",
    "Asia/Baku",
    "America/Barbados",
    "Asia/Dhaka",
    "Europe/Brussels",
    "Europe/Sofia",
    "Atlantic/Bermuda",
    "Asia/Brunei",
    "America/La_Paz",
    "America/Noronha",
    "America/Belem",
    "America/Fortaleza",
    "America/Recife",
    "America/Araguaina",
    "America/Maceio",
    "America/Bahia",
    "America/Sao_Paulo",
    "America/Campo_Grande",
    "America/Cuiaba",
    "America/Santarem",
    "America/Porto_Velho",
    "America/Boa_Vista",
    "America/Manaus",
    "America/Eirunepe",
    "America/Rio_Branco",
    "America/Nassau",
    "Asia/Thimphu",
    "Europe/Minsk",
    "America/Belize",
    "America/St_Johns",
    "America/Halifax",
    "America/Glace_Bay",
    "America/Moncton",
    "America/Goose_Bay",
    "America/Blanc-Sablon",
    "America/Toronto",
    "America/Nipigon",
    "America/Thunder_Bay",
    "America/Iqaluit",
    "America/Pangnirtung",
    "America/Atikokan",
    "America/Winnipeg",
    "America/Rainy_River",
    "America/Resolute",
    "America/Rankin_Inlet",
    "America/Regina",
    "America/Swift_Current",
    "America/Edmonton",
    "America/Cambridge_Bay",
    "America/Yellowknife",
    "America/Inuvik",
    "America/Creston",
    "America/Dawson_Creek",
    "America/Fort_Nelson",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Dawson",
    "Indian/Cocos",
    "Europe/Zurich",
    "Africa/Abidjan",
    "Pacific/Rarotonga",
    "America/Santiago",
    "America/Punta_Arenas",
    "Pacific/Easter",
    "Asia/Shanghai",
    "Asia/Urumqi",
    "America/Bogota",
    "America/Costa_Rica",
    "America/Havana",
    "Atlantic/Cape_Verde",
    "America/Curacao",
    "Indian/Christmas",
    "Asia/Nicosia",
    "Asia/Famagusta",
    "Europe/Prague",
    "Europe/Berlin",
    "Europe/Copenhagen",
    "America/Santo_Domingo",
    "Africa/Algiers",
    "America/Guayaquil",
    "Pacific/Galapagos",
    "Europe/Tallinn",
    "Africa/Cairo",
    "Africa/El_Aaiun",
    "Europe/Madrid",
    "Africa/Ceuta",
    "Atlantic/Canary",
    "Europe/Helsinki",
    "Pacific/Fiji",
    "Atlantic/Stanley",
    "Pacific/Chuuk",
    "Pacific/Pohnpei",
    "Pacific/Kosrae",
    "Atlantic/Faroe",
    "Europe/Paris",
    "Europe/London",
    "Asia/Tbilisi",
    "America/Cayenne",
    "Africa/Accra",
    "Europe/Gibraltar",
    "America/Godthab",
    "America/Danmarkshavn",
    "America/Scoresbysund",
    "America/Thule",
    "Europe/Athens",
    "Atlantic/South_Georgia",
    "America/Guatemala",
    "Pacific/Guam",
    "Africa/Bissau",
    "America/Guyana",
    "Asia/Hong_Kong",
    "America/Tegucigalpa",
    "America/Port-au-Prince",
    "Europe/Budapest",
    "Asia/Jakarta",
    "Asia/Pontianak",
    "Asia/Makassar",
    "Asia/Jayapura",
    "Europe/Dublin",
    "Asia/Jerusalem",
    "Asia/Kolkata",
    "Indian/Chagos",
    "Asia/Baghdad",
    "Asia/Tehran",
    "Atlantic/Reykjavik",
    "Europe/Rome",
    "America/Jamaica",
    "Asia/Amman",
    "Asia/Tokyo",
    "Africa/Nairobi",
    "Asia/Bishkek",
    "Pacific/Tarawa",
    "Pacific/Enderbury",
    "Pacific/Kiritimati",
    "Asia/Pyongyang",
    "Asia/Seoul",
    "Asia/Almaty",
    "Asia/Qyzylorda",
    "Asia/Qostanay",
    "Asia/Aqtobe",
    "Asia/Aqtau",
    "Asia/Atyrau",
    "Asia/Oral",
    "Asia/Beirut",
    "Asia/Colombo",
    "Africa/Monrovia",
    "Europe/Vilnius",
    "Europe/Luxembourg",
    "Europe/Riga",
    "Africa/Tripoli",
    "Africa/Casablanca",
    "Europe/Monaco",
    "Europe/Chisinau",
    "Pacific/Majuro",
    "Pacific/Kwajalein",
    "Asia/Yangon",
    "Asia/Ulaanbaatar",
    "Asia/Hovd",
    "Asia/Choibalsan",
    "Asia/Macau",
    "America/Martinique",
    "Europe/Malta",
    "Indian/Mauritius",
    "Indian/Maldives",
    "America/Mexico_City",
    "America/Cancun",
    "America/Merida",
    "America/Monterrey",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Chihuahua",
    "America/Ojinaga",
    "America/Hermosillo",
    "America/Tijuana",
    "America/Bahia_Banderas",
    "Asia/Kuala_Lumpur",
    "Asia/Kuching",
    "Africa/Maputo",
    "Africa/Windhoek",
    "Pacific/Noumea",
    "Pacific/Norfolk",
    "Africa/Lagos",
    "America/Managua",
    "Europe/Amsterdam",
    "Europe/Oslo",
    "Asia/Kathmandu",
    "Pacific/Nauru",
    "Pacific/Niue",
    "Pacific/Auckland",
    "Pacific/Chatham",
    "America/Panama",
    "America/Lima",
    "Pacific/Tahiti",
    "Pacific/Marquesas",
    "Pacific/Gambier",
    "Pacific/Port_Moresby",
    "Pacific/Bougainville",
    "Asia/Manila",
    "Asia/Karachi",
    "Europe/Warsaw",
    "America/Miquelon",
    "Pacific/Pitcairn",
    "America/Puerto_Rico",
    "Asia/Gaza",
    "Asia/Hebron",
    "Europe/Lisbon",
    "Atlantic/Madeira",
    "Atlantic/Azores",
    "Pacific/Palau",
    "America/Asuncion",
    "Asia/Qatar",
    "Indian/Reunion",
    "Europe/Bucharest",
    "Europe/Belgrade",
    "Europe/Kaliningrad",
    "Europe/Moscow",
    "Europe/Simferopol",
    "Europe/Kirov",
    "Europe/Astrakhan",
    "Europe/Volgograd",
    "Europe/Saratov",
    "Europe/Ulyanovsk",
    "Europe/Samara",
    "Asia/Yekaterinburg",
    "Asia/Omsk",
    "Asia/Novosibirsk",
    "Asia/Barnaul",
    "Asia/Tomsk",
    "Asia/Novokuznetsk",
    "Asia/Krasnoyarsk",
    "Asia/Irkutsk",
    "Asia/Chita",
    "Asia/Yakutsk",
    "Asia/Khandyga",
    "Asia/Vladivostok",
    "Asia/Ust-Nera",
    "Asia/Magadan",
    "Asia/Sakhalin",
    "Asia/Srednekolymsk",
    "Asia/Kamchatka",
    "Asia/Anadyr",
    "Asia/Riyadh",
    "Pacific/Guadalcanal",
    "Indian/Mahe",
    "Africa/Khartoum",
    "Europe/Stockholm",
    "Asia/Singapore",
    "America/Paramaribo",
    "Africa/Juba",
    "Africa/Sao_Tome",
    "America/El_Salvador",
    "Asia/Damascus",
    "America/Grand_Turk",
    "Africa/Ndjamena",
    "Indian/Kerguelen",
    "Asia/Bangkok",
    "Asia/Dushanbe",
    "Pacific/Fakaofo",
    "Asia/Dili",
    "Asia/Ashgabat",
    "Africa/Tunis",
    "Pacific/Tongatapu",
    "Europe/Istanbul",
    "America/Port_of_Spain",
    "Pacific/Funafuti",
    "Asia/Taipei",
    "Europe/Kiev",
    "Europe/Uzhgorod",
    "Europe/Zaporozhye",
    "Pacific/Wake",
    "America/New_York",
    "America/Detroit",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Indiana/Indianapolis",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Vevay",
    "America/Chicago",
    "America/Indiana/Tell_City",
    "America/Indiana/Knox",
    "America/Menominee",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/North_Dakota/Beulah",
    "America/Denver",
    "America/Boise",
    "America/Phoenix",
    "America/Los_Angeles",
    "America/Anchorage",
    "America/Juneau",
    "America/Sitka",
    "America/Metlakatla",
    "America/Yakutat",
    "America/Nome",
    "America/Adak",
    "Pacific/Honolulu",
    "America/Montevideo",
    "Asia/Samarkand",
    "Asia/Tashkent",
    "America/Caracas",
    "Asia/Ho_Chi_Minh",
    "Pacific/Efate",
    "Pacific/Wallis",
    "Pacific/Apia",
    "Africa/Johannesburg",
  ];
  const timeZonesWithLabelValue = aryIanaTimeZones.map((timeZone) => ({
    label: timeZone,
    value: timeZone,
  }));
  const handleDateChange = async (newDate) => {
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    const sDate = moment(newDate.$d).format("YYYY-MM-DD");
    if (sDate < currentDate) {
      swal({
        text: "Please select a date from today onwards.",
        icon: "warning",
      });
      setPrevDate(true);
    } else if (sDate >= currentDate) {
      setSelectedDate(moment(newDate.$d).format("YYYY-MM-DD"));
      setIsWeekendDate(newDate.$d);
      setPrevDate(false);
      const formData = new FormData();
      formData.append("date", moment(newDate.$d).format("YYYY-MM-DD"));
      try {
        const response = await axios.post(
          `${ConfigUrl.apiUrl}checkAvailability`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response) {
          setTimeIds(response.data.data);
          const timeId = response.data.data.map((ob) => ob.time_id);
          const filterTimeSlots = timeSlots.filter(
            (slot) => !timeId.includes(slot.id)
          );
          // setFilterTimeSlots([]);
          // if (selectedDate != moment(new Date()).format("YYYY-MM-DD")) {
          setFilterTimeSlots(filterTimeSlots);
          // } else {
          //   setFilterTimeSlots([]);
          // }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    window.scrollTo({
      top: "0",
      behavior: "instant",
    });
  }, []);
  const loadDate = () => {
    const currentDate = new Date();
    isWeekend(currentDate);
    handleDateChange(currentDate);
  };
  useEffect(() => {
    loadDate();
  }, []);
  const handleSubmitAppoinment = async (e) => {
    e.preventDefault();
    if (selectedSlot !== null && selectedSlot !== "") {
      setModalCreateMeeting(true);
      const formData = new FormData();
      formData.append("date", selectedDate);
      formData.append("time", time);
      formData.append("time_id", selectedSlot);
      formData.append("timezone", selectedTimeZone.value);
      formData.append("book_id", bookingIdMain);
      try {
        const response = await axios.post(
          `${ConfigUrl.apiUrl}updateSlot`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.success) {
          setTimeout(() => {
            setModalCreateMeeting(false);
            Swal.fire({
              title: `Meeting link created and sent to <span class="text-blue fw-light fs-5">${email}</span>`,
              text: "Check Email",
              icon: "success",
            }).then(() => {
              navigate(`${ConfigUrl.appUrl}`);
              setModal(false);
            });
          }, 500);
        }
      } catch (error) {
        setModalCreateMeeting(false);
        swal({
          title: `Something went wrong`,
          text: "Please try again",
          icon: "warning",
        });
        console.log(error);
      }
    } else {
      swal({
        text: "Please Select any time slot",
        icon: "warning",
      });
    }
  };

  // ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,

  const todaySlots = timeSlots.filter((slot) => {
    return moment().add(30, "minute").isBefore(moment(slot.from, "hh:mm A"));
  });

  return (
    <div>
      <Nav />

      <div className="container-main pt-0 bg-main">
        <form onSubmit={handleSubmitAppoinment}>
          <div className="row pt-4">
            <div className="col-md-6 ps-0">
              {/* <input type="date" onChange={() => handleDateChange} /> */}
              <DatePicker
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
                value={selectedDate}
              />

              <div className="row d-flex align-items-center justify-content-center pb-mbd-2">
                <div className="col-6 d-flex justify-content-center align-items-center">
                  <i className="fa-solid fa-earth-americas text-blue fs-6"></i>{" "}
                  &nbsp;{" "}
                  <span style={{ color: "#484848", fontSize: "13px" }}>
                    Select Timezone
                  </span>
                </div>
                <div className="col-6 ps-2">
                  <select
                    defaultValue="Europe/London"
                    className="bg-transparent border-0 w-50"
                    value={selectedTimeZone.value}
                    onChange={(e) =>
                      setSelectedTimeZone({
                        value: e.target.value,
                        label: e.target.value,
                      })
                    }
                  >
                    <option value={"Europe/London"}>Europe/London</option>
                    {timeZonesWithLabelValue.map((ob, index) => (
                      <option key={index} value={ob.value}>
                        {ob.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              <div
                className={` ps-0  pt-2 ${
                  prevDate || isWeekend(selectedDate)
                    ? "d-flex align-items-center"
                    : ""
                }`}
              >
                <div
                  className={`bg-white shadow-sm  p-4 ${
                    prevDate || isWeekend(selectedDate)
                      ? ""
                      : "border border-1 shadow-sm"
                  }`}
                >
                  {selectedDate === moment().format("YYYY-MM-DD") ? (
                    todaySlots.length !== 0 ? (
                      <h2 className="text-mbd-center fs-mbd-3">
                        Select a time slot
                      </h2>
                    ) : (
                      ""
                    )
                  ) : (
                    !isWeekend(selectedDate) &&
                    !prevDate &&
                    filterTimeSlots.length !== 0 && (
                      <h2 className="text-mbd-center fs-mbd-3">
                        Select a time slot
                      </h2>
                    )
                  )}
                  {filterTimeSlots.length !== 0 &&
                    !isWeekend(isWeekendDate) &&
                    !prevDate && (
                      <div className="row ">
                        {selectedDate === moment().format("YYYY-MM-DD")
                          ? todaySlots.map((slot, index) => {
                              return (
                                <div key={slot.id} className="col-md-6 mb-2">
                                  <div
                                    className={`time-slot border text-center ${
                                      selectedSlot === slot.id
                                        ? "selected-timeSlot"
                                        : ""
                                    }`}
                                    onClick={() => handleSlotClick(slot)}
                                  >
                                    {`${slot.from} - ${slot.to}`}
                                  </div>
                                </div>
                              );
                            })
                          : filterTimeSlots.map((slot, index) => (
                              <div key={slot.id} className="col-md-6 mb-2">
                                <div
                                  className={`time-slot border text-center ${
                                    selectedSlot === slot.id
                                      ? "selected-timeSlot"
                                      : ""
                                  }`}
                                  onClick={() => handleSlotClick(slot)}
                                >
                                  {`${slot.from} - ${slot.to}`}
                                </div>
                              </div>
                            ))}
                        {}
                      </div>
                    )}
                  {selectedDate === moment().format("YYYY-MM-DD") ? (
                    todaySlots.length == 0 ? (
                      <div className="row">
                        <div className="col-12">
                          <div className="alert alert-danger" role="alert">
                            <h4>There are no slots available.</h4>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  ) : filterTimeSlots.length == 0 ||
                    prevDate ||
                    isWeekend(selectedDate) ? (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger" role="alert">
                          <h4>There are no slots available.</h4>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex mt-4 justify-content-between justify-content-mbd-center align-items-center py-0">
            <div className=""></div>
            <div className="pt-mbd-4">
              <button
                className="m-0 btn-cancle  btn-blue px-5 me-3 rounded-0"
                type="button"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>

              {!prevDate && !isWeekend(isWeekendDate) && (
                <button
                  type="submit"
                  className="btn-blue scrool shadow m-0 px-5 rounded-0"
                  style={{ cursor: "pointer" }}
                >
                  Book Appoinment
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <Footer />
      <Modal
        isOpen={modalCreateMeeting}
        toggle={toggleModalCreateMeeting}
        // size="sm"
        centered
        className="createMeeting z-3 "
      >
        <ModalBody className="modal-body border-0 p-0">
          <div className="container py-5">
            <div className="row d-xxl-flex d-justify-content-center d-align-items-center">
              <div className="col-12 d-flex justify-content-center fs-3 text-muted mb-3">
                <i class={`fa-solid ${modalContent.icon} `}></i>
              </div>
              <div className="col-lg-12 px-0">
                <div>
                  <h5 className="modal-title">{modalContent.title}</h5>
                  <p className="text-center">{modalContent.text}</p>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CalenderIndex;
